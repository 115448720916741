import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../invite.css"
import Constants from "../constants/api"
const Invitation = () => {
  const [name, setName] = useState("")
  const [stagename, setStagename] = useState("")
  const [twitter, setTwitter] = useState("")
  const [instagram, setInstagram] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  const [errorMsg, setErrorMsg] = useState("")
  const [loading, setLoading] = useState(false)
  const [canuse, setCanuse] = useState(true)
  const [sentSuccess, setSentSuccess] = useState(false)

  const flashError = msg => {
    setErrorMsg(msg)
    setTimeout(() => {
      setErrorMsg("")
    }, 3500)
  }

  const verifyForm = () => {
    if (name === "") {
      return { status: false, msg: "Please fill out your name." }
    }

    if (stagename === "") {
      return { status: false, msg: "Please fill out your stage name." }
    }

    if (instagram === "" && twitter === "") {
      return {
        status: false,
        msg: "Please provide a Twitter OR Instagram profile of yourself.",
      }
    }

    if (email === "") {
      return { status: false, msg: "Please provide an email address." }
    }

    if (phone === "") {
      return { status: false, msg: "Please fill your phone number." }
    }

    return { status: true, msg: "" }
  }
  const handleSaveInfo = () => {
    if (loading) return
    const formData = new FormData()
    const verify = verifyForm()
    if (!verify.status) {
      flashError(verify.msg)
      return
    }
    setLoading(true)
    if (canuse) {
      formData.append("canuse", "y")
    } else {
      formData.append("canuse", "n")
    }
    formData.append("name", name)
    formData.append("email", email)
    formData.append("stagename", stagename)
    formData.append("twitter", twitter)
    formData.append("instagram", instagram)
    formData.append("phone", phone)

    const csrf = getCookie("XSRF-TOKEN")
    fetch(`${Constants.RIVER}trying-to-join`, {
      method: "POST",
      headers: {
        "X-XSRF-TOKEN": decodeURIComponent(csrf),
      },
      body: formData,
    })
      .then(res => res.json())
      .then(res => {
        //handle
        if ("success" in res) {
          setSentSuccess(true)
          return
        }
        setErrorMsg(
          "Something went wrong (error code: 38S-z), please contact hello@addfriends.com!"
        )
      })
  }
  const getCookie = name => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2)
      return parts
        .pop()
        .split(";")
        .shift()
  }

  return (
    <Layout>
      <SEO title="Addfriends Creator Application" />
      <div className="invite-container">
        {errorMsg !== "" ? (
          <div className="invite-error-message">{errorMsg}</div>
        ) : null}
        {sentSuccess ? (
          <div className="app-success">
            <h2>Success!</h2>
            <p>
              Thank you for your interest. We are processing your application
              and will reach out to you in the next 48 hours!
            </p>
          </div>
        ) : (
          <div className="invite-form">
            <div className="form-item">
              <span>Legal Name</span>
              <input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="form-item">
              <span>Email Address</span>
              <input
                type="email"
                value={email}
                required
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <div className="form-item">
              <span>Stage Name</span>
              <input
                type="text"
                value={stagename}
                onChange={e => setStagename(e.target.value)}
              />
            </div>
            <div className="form-item">
              <span>Twitter</span>
              <input
                type="text"
                value={twitter}
                onChange={e => setTwitter(e.target.value)}
              />
            </div>
            <div className="form-item">
              <span>Instagram</span>
              <input
                type="text"
                value={instagram}
                onChange={e => setInstagram(e.target.value)}
              />
            </div>
            <div className="form-item">
              <span>Phone #</span>
              <input
                type="tel"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>
            <div className="form-item right">
              {/* <div className="full">
                can we use images / videos from your social media accounts on
                the tour?
                <div className="invite-choice">
                  <div
                    className={canuse ? "invite-btn active" : "invite-btn"}
                    onClick={() => {
                      setCanuse(true)
                    }}
                  >
                    YES
                  </div>
                  <div
                    className={canuse ? "invite-btn" : "invite-btn active"}
                    onClick={() => {
                      setCanuse(false)
                    }}
                  >
                    NO
                  </div>
                </div>
              </div> */}

              <div className="form-item-full">
                <button onClick={handleSaveInfo}>
                  {loading ? (
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    "SEND"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Invitation
